import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Main, Root, MusicForm, AppLogin } from "../pages";
import { api } from "../utils/api";
import { SWRConfig } from "swr";

const App = () => {
  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  const [isToken, setIsToken] = useState(false);
  useEffect(() => {
    if (!localStorage.token || localStorage.token === "undefined") {
      setIsToken(false);
    } else {
      setIsToken(true);
    }
  }, []);

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/app/login" component={AppLogin} />
          <Route exact path="/main" component={isToken ? Main : Root} />
          <Route
            exact
            path="/register"
            component={isToken ? MusicForm : Root}
          />

          <Route
            exact
            path="/edit/:editId"
            component={isToken ? MusicForm : Root}
          />
          <Route
            exact
            path="/done_edit/:editId"
            component={isToken ? MusicForm : Root}
          />
          <Route
            exact
            path="/refuse/:editId"
            component={isToken ? MusicForm : Root}
          />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
